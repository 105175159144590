import { useCallback } from "react";
import { ProductAvailabilityOptions, PublicAvailability } from "../api/generated";
import { useTranslation } from "react-i18next";

/**
 * Processes the availablity of an item and provide the theme and value necessary to display it in a readabale way
 * @returns object
 */
const useAvailability = () => {
  const { t } = useTranslation();

  return useCallback((available: PublicAvailability) => {
    switch (available.currentAvailability) {
      case ProductAvailabilityOptions.ImmediatelyAvailable:
        return {
          available,
          style: {
            textStyle: "text-success",
            backgroundStyle: "bg-success-alpha",
            borderStyle: "border-success",
            textValue: t("Available"),
          },
        };
      case ProductAvailabilityOptions.AvailableIn || ProductAvailabilityOptions.AvailableFrom:
        return {
          available,
          style: {
            textStyle: "text-warning",
            backgroundStyle: "bg-warning-alpha",
            borderStyle: "border-warning",
            textValue: `${t("Available in")} ${available.availableInDaysFrom ?? ""}-${
              available.availableInDaysTo ?? ""
            } ${t("days")}`,
          },
        };
      case ProductAvailabilityOptions.Unavailable:
        return {
          available,
          style: {
            textStyle: "text-fail",
            backgroundStyle: "bg-fail-alpha",
            borderStyle: "border-fail",
            textValue: t("Unavailable"),
          },
        };
      default:
        return {
          available,
          style: {
            textStyle: "text-fail",
            backgroundStyle: "bg-fail-alpha",
            borderStyle: "border-fail",
            textValue: t("Unavailable"),
          },
        };
    }
  }, []);
};

export default useAvailability;
