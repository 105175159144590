import { TFunction } from "i18next";
import * as yup from "./yup";

/**
 * Manages the validation for signing up users
 */
export type SignUp = "firstName" | "lastName" | "email";
export type SignUpConsent = "termsAndCondition" | "legalConsent" | "coverage";
export type SignUpInsurance = "insurance";

/**
 * User sign up schema accepts firstName, lastName, email, termsAndCondition, legalConsent, coverage and insurance
 */
export type SignUpProps = {
  [key in SignUp]?: string;
};

/**
 * User sign up schema accepts firstName, lastName, email, termsAndCondition, legalConsent, coverage and insurance
 */
export type SignUpConsentProps = {
  [key in SignUpConsent]?: boolean;
};

/**
 * User sign up schema accepts firstName, lastName, email, termsAndCondition, legalConsent, coverage and insurance
 */
export type SignUpInsuranceProps = {
  [key in SignUpInsurance]?: "private" | "public";
};

/**
 * User sign up default value is empty for all fields it accepts
 */

export type SignupFormProps = SignUpProps &
  SignUpInsuranceProps & {
    coverage?: "yes" | "no";
    termsAndCondition: boolean;
    legalConsent: boolean;
  };

/**
 * User sign up validation
 * firstName is required, with min of 1 and max of 255 characters
 * lastName is required, with min of 1 and max of 255 characters
 * email is required, and it must follow conventional email pattern
 * insurance is required and can only be private or public
 * coverage is not required and it is either true or false
 * termsAndCondition is required and can only be true
 * legalConsent is required and can only be true
 */
export const SignUpSchema = (t: TFunction<"translation", undefined, "translation">) => {
  yup.setYupLocale(t);

  return yup
    .object({
      firstName: yup.string().min(2).max(255).required(),
      lastName: yup.string().min(2).max(255).required(),
      email: yup.string().email().required(),
      insurance: yup.string().oneOf(["private", "public"]).required(),
      coverage: yup.string().oneOf(["yes", "no"]),
      termsAndCondition: yup.boolean().oneOf([true]).required(),
      legalConsent: yup.boolean().notRequired(),
    })
    .required();
};
