import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useQueries } from "react-query";
import { useAuthState } from "../state/useAuthState";
import { useGraphql } from "./useGraphql";

// useNcm holds ncmId, products, user of a particular ncm
const useNcm = () => {
  const route = useRouter();
  const graphql = useGraphql();
  const ncmId = (route.query as any)?.id;

  const isProductPath = route.pathname.toLowerCase().includes("product");

  const results = useQueries([
    {
      queryKey: ["ncm", ncmId],
      queryFn: () => graphql.ncm({ input: { id: ncmId } }),
      enabled: !isEmpty(ncmId) && !isProductPath,
    },
  ]);

  const result = results?.[0] ?? undefined;
  const resultNcm = result?.data?.ncm?.result;

  const { userInfo, userType } = useAuthState();

  return {
    ...resultNcm,
    isLoading: results.some((i) => i.isLoading || i.isFetching || i.isRefetching),
    refetch: () => {
      results?.[0]?.refetch();
    },
    userInfo,
    userType,
    errors: results?.[0]?.error,
  };
};

export default useNcm;
