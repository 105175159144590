import TiktokPixel from "tiktok-pixel";
import environment from "@gruene-brise/data-access/config/environment";
import useNcm from "@gruene-brise/data-access/hooks/useNcm";
import {
  DeliveryOptionType,
  ProductAvailabilityOptions,
  ProviderOffer,
} from "libs/data-access/src/lib/api/generated";
import useAvailability from "libs/data-access/src/lib/hooks/useAvailability";
import useCurrencyFormatter from "libs/data-access/src/lib/hooks/useCurrencyFormatter";
import { useGraphql } from "libs/data-access/src/lib/hooks/useGraphql";
import useGraphqlError from "libs/data-access/src/lib/hooks/useGraphqlError";
import { isEmpty, isUndefined, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { CgArrowRight } from "react-icons/cg";
import { useMutation } from "react-query";
import { Checkbox } from "../form/Checkbox";
import IUnderstandModal from "./IUnderstandModal";
import { useAuthState } from "@gruene-brise/data-access/state/useAuthState";
import usePopUpModal, { useModal } from "../Modal";
import { useRouter } from "next/router";
import UserSignUp from "./UserSignUp";
import UserSignIn from "./UserSignIn";
import { useGlobalState } from "@gruene-brise/data-access/state/useGlobalState";
import { useStatusTranslations } from "@gruene-brise/data-access/hooks/useStatusTranslations";
import DHLIcon from "../icon/DHLIcon";
import DeliveryBusIcon from "../icon/DeliveryBusIcon";
import classNames from "classnames";
import { useGetAvailableDeliveryOptions } from "libs/data-access/src/lib/hooks/usePharmacy";
import { Spinner } from "@chakra-ui/react";

const DeliveryOptions: {
  icon: JSX.Element;
  key: DeliveryOptionType;
  title: string;
}[] = [
  { key: DeliveryOptionType.Dhl, icon: <DHLIcon />, title: "Standard Shipping" },
  {
    key: DeliveryOptionType.Go,
    icon: <img src='/images/go.png' className='h-[30px]' />,
    title: "Express Delivery",
  },
  {
    key: DeliveryOptionType.Pickup,
    icon: <DeliveryBusIcon />,
    title: "Pick up",
  },
];

function DeliveryOption({ type, price }: { type: DeliveryOptionType; price: number }) {
  const { t } = useTranslation();
  const deliveryOption = DeliveryOptions.find((i) => i.key === type);
  const numberFormat = useCurrencyFormatter();
  const title = t(deliveryOption?.title ?? "");

  return (
    <div className='flex flex-row items-center gap-2 justify-between'>
      <div className='flex flex-col text-primary-50 text-xs'>
        <div>{title}</div>
        <div>{numberFormat(price)}</div>
      </div>
      <div className='flex flex-row gap-2 items-center text-primary px-3 py-2 rounded-lg bg-primary-alpha ml-auto w-28 justify-center'>
        {deliveryOption?.icon}
      </div>
    </div>
  );
}

export interface PharmacyCartItemProps extends ProviderOffer {
  onPress?(id: string): void;
  hideModal?: boolean;
  selectedPharmacy?: string;
  lowestPrice?: number;
}

/**
 * PhamacyCartItem shows according to the design pharmacy available that has the items in cart
 *
 * @param availability
 * @param pharmacy
 * @param products
 * @param id
 * @param subTotal
 *
 * @param JSX.Element
 * @returns
 */
const PharmacyCartItem = ({
  availability,
  pharmacy: { name, logoURL, customShippingDetails },
  id,
  products,
  subTotal,
  onPress,
  selectedPharmacy,
  hideModal,
  lowestPrice,
}: PharmacyCartItemProps) => {
  const priceFormat = useCurrencyFormatter();
  const { t } = useTranslation();
  const availabilityHook = useAvailability();
  const availabilityValues = availabilityHook(availability);
  const [expandShippingDetails, setExpandShippingDetails] = useState(false);
  const [expandPricingDetails, setExpandPricingDetails] = useState(false);

  const { data: availableShippingDetails, isLoading: isShippingDetailsLoading } =
    useGetAvailableDeliveryOptions({
      pharmacyId: id,
      subTotal: lowestPrice,
      enabled: expandShippingDetails,
    });

  const { productUnitTranslation } = useStatusTranslations();
  const [openAlert, setOpenAlert] = useState(false);

  const data = products.map((i) => {
    const price = i.price * i.quantity + (i?.basePrice ?? 0);

    return {
      title: i.product.fullName,
      quantity: i.quantity,
      startingPrice: price,
      price: priceFormat(price),
      unit: productUnitTranslation(i.product.unit),
      unitValue: i.product.unitValue,
      availabilityValues: availabilityHook(i.product.availability),
    };
  });
  const items = useMemo(() => {
    return sortBy(data, "startingPrice").reverse();
  }, [data]);

  const { userInfo } = useAuthState();
  const { language } = useGlobalState();

  const ncm = useNcm();

  const graphql = useGraphql();
  const { showError } = useGraphqlError();

  const createCheckout = useMutation(() =>
    graphql.createCheckout({
      input: {
        providerOfferId: id,
        products: products.map((i) => {
          return { id: i.product.id, quantity: i.quantity };
        }),
        ncmId: isEmpty(ncm?.id) || isUndefined(ncm?.id) ? undefined : ncm?.id,
      },
    }),
  );

  const goToCheckout = async () => {
    try {
      const {
        createCheckout: { checkout },
      } = await createCheckout.mutateAsync();
      const { id, pharmacy } = checkout;

      const subdomain = pharmacy?.subDomain ?? "";
      const path = `shopping-cart/information-shipping-payment?checkoutId=${id}&language=${language}`;

      let url = new URL(environment.webshopBaseUrl);
      const hostname = url.hostname;

      TiktokPixel.track(environment.events.AddToCart, checkout);

      if (!isEmpty(subdomain) && !hostname.includes(subdomain as string)) {
        const newUrl = url.host.replace(url.hostname, subdomain + "." + url.hostname);

        url = new URL(url.href.replace(url.host, newUrl));
      }

      window.location.href = url.href + path;
    } catch (e) {
      showError(e);
    }
  };

  const handleSelection = async () => {
    if (!isUndefined(onPress)) {
      const newCheckout = await createCheckout.mutateAsync();
      return onPress?.(newCheckout?.createCheckout?.checkout?.id);
    }
    await goToCheckout();
  };
  const {
    ConfirmationModal,
    close: closeConfirmationModal,
    open: openConfirmationModal,
  } = usePopUpModal();
  const { Modal, close: closeModal, open: openModal } = useModal();
  const [isRegister, setIsRegister] = useState(false);
  const router = useRouter();
  const selectPharmacy = () => {
    if (hideModal) {
      onPress?.(id);
    } else {
      setOpenAlert(!openAlert);
    }
  };
  const disabled = availability.currentAvailability === ProductAvailabilityOptions.Unavailable;
  console.log("Express delivery", t("Express delivery"));

  return (
    <div className='w-full p-5 my-1 shadow-button_sm cursor-pointer border-[3px] border-white rounded-2xl font-gellix mb-10 bg-white'>
      <div className='w-full flex flex-row py-5 items-center'>
        <div className='mr-4'>
          <img src={logoURL ?? ""} className='w-[50px]' />
        </div>
        <div className='text-sm md:text-md xl:text-md lg:text-md font-semibold line-clamp-1'>
          {name}
        </div>
      </div>
      <div className='w-full flex flex-row justify-between'>
        <span
          className={`
                ${availabilityValues.style.backgroundStyle} ${availabilityValues.style.textStyle}
                  px-5 flex items-center text-center h-8 text-[8px] md:text-xs xl:text-xs lg:text-xs font-500 rounded-lg `}
        >
          {availabilityValues.style.textValue}
        </span>
        {availability.currentAvailability !== ProductAvailabilityOptions.Unavailable && (
          <div>
            <div className=' text-primary-50 font-light text-xs'>{t("Total Price")}</div>
            <span className='text-[14px] md:text-lg xl:text-lg lg:text-lg font-semibold'>
              {priceFormat(subTotal)}
            </span>
          </div>
        )}
      </div>
      <div className='mt-3 h-[1px] bg-primary-10 w-full' />
      <div className='flex flex-row'>
        <div className='justify-between w-full my-3 transition-all duration-150'>
          <div
            className='flex flex-row items-center text-primary-50 cursor-pointer'
            onClick={() => {
              // toggle expandShippingDetails
              setExpandPricingDetails(!expandPricingDetails);
            }}
          >
            <span className='mr-2 text-[10px] line-clamp-1'>{t("Pricing Details")}</span>
            {expandPricingDetails ? <BsChevronUp /> : <BsChevronDown />}
          </div>
        </div>

        <div className='justify-between w-full my-3 transition-all duration-150'>
          <div
            className='flex flex-row items-center text-primary-50 cursor-pointer'
            onClick={() => {
              setExpandShippingDetails(!expandShippingDetails);
            }}
          >
            <span className='mr-2 text-[10px] line-clamp-1'>{t("Shipping Details")}</span>
            {expandShippingDetails ? <BsChevronUp /> : <BsChevronDown />}
          </div>
        </div>
      </div>

      <ConfirmationModal
        className='w-[400px] md:w-[600px] lg:w-[600px] xl:w-[600px]'
        actionButtonOption={{
          title: t("Sign in")!,
          onPress: async (e) => {
            e.preventDefault();
            closeConfirmationModal();
            setIsRegister(false);
            openModal();
          },
        }}
        showXClose
        cancelText={t("Create Account")!}
        onCloseClicked={() => {
          closeConfirmationModal();
          setIsRegister(true);
          openModal();
        }}
        title={t("You are not signed in")!}
        warningMessage={
          t("You need to be signed in to continue. Do you want to sign in or create an account?")!
        }
      />

      <Modal title=''>
        {isRegister ? (
          <div className='w-full md:w-[800px]'>
            <UserSignUp
              onLogin={() => {
                setIsRegister(false);
              }}
              hideDoctor
              hideHeader
              className='!w-full'
            />
          </div>
        ) : (
          <div className='w-full md:w-[800px]'>
            <UserSignIn
              onLogin={() => {
                closeModal();
                selectPharmacy();
              }}
              onRegister={() => {
                setIsRegister(true);
              }}
              hideDoctor
              hideHeader
              className='!w-full'
            />
          </div>
        )}
      </Modal>

      {expandPricingDetails && (
        <div className='w-full flex flex-col gap-3 transition-all duration-300 pb-5'>
          {items.map((i, idx) => {
            return (
              <div key={idx} className='w-full text-primary-50 text-xs'>
                <div className='mb-1'>{i.title}</div>
                <div className='flex flex-row w-full items-center gap-2  justify-between'>
                  <div className='w-[20%]'>
                    {i.quantity * i.unitValue}
                    {i.unit}
                  </div>
                  <div className={"w-[20%]"}>{i.startingPrice > 0 ? i.price : ""}</div>
                  <div className={`w-[70%] justify-end flex`}>
                    <span
                      className={`bg-white ${i.availabilityValues.style.textStyle}  ${i.availabilityValues.style.borderStyle} border-[1px]
                  px-2 xl:px-5 lg:px-5 flex items-center text-center h-8 text-[8px] md:text-xs xl:text-xs lg:text-xs font-500 rounded-lg capitalize `}
                    >
                      {i.availabilityValues.style.textValue}
                    </span>
                  </div>
                </div>

                {idx !== items.length - 1 && <div className='mt-3 h-[1px] bg-primary-10 w-full' />}
              </div>
            );
          })}
        </div>
      )}

      <div
        className={classNames(
          "h-[3px] border-b border-t border-[bg-primary-10] w-full bg-transparent mb-5",
          {
            hidden: !expandPricingDetails || !expandShippingDetails,
          },
        )}
      />

      {expandShippingDetails ? (
        <div className={classNames("w-full flex flex-col gap-3 transition-all duration-300 pb-5")}>
          {!isShippingDetailsLoading ? (
            availableShippingDetails?.map((shippingDetail) =>
              shippingDetail ? (
                <DeliveryOption
                  key={shippingDetail.id}
                  type={shippingDetail.type}
                  price={shippingDetail.price}
                />
              ) : null,
            )
          ) : (
            <Spinner size='sm' />
          )}
        </div>
      ) : null}

      <button
        className={`flex flex-row gap-2 items-center text-primary px-3 py-2 rounded-lg bg-primary-alpha ml-auto ${
          disabled ? "hidden" : "flex"
        }`}
        onClick={() => {
          if (isUndefined(userInfo)) {
            router.push(router.pathname, { query: null });
            openConfirmationModal();
            return;
          }

          selectPharmacy();
        }}
      >
        {hideModal && <Checkbox checked={selectedPharmacy == id} />}
        <span className=' text-xs font-semibold capitalize line-clamp-1'>
          {t("choose pharmacy")}
        </span>
        {!hideModal && <CgArrowRight />}
      </button>

      <IUnderstandModal
        visibility={openAlert}
        onPress={async () => {
          handleSelection();
        }}
        onClose={() => {
          setOpenAlert(false);
        }}
        isLoading={createCheckout.isLoading}
      />
    </div>
  );
};

export default PharmacyCartItem;
