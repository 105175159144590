import { useTranslation } from "react-i18next";
import { BsArrowRight, BsX } from "react-icons/bs";
import FormButton from "../form/FormButton";
import BloomwellLogo from "../icon/BloomwellLogo";

export interface IUnderstandModalProps {
  visibility?: boolean;
  onClose?(): void;
  className?: string;
  onPress?(): void;
  isLoading?: boolean;
}

const IUnderstandModal = ({
  isLoading,
  visibility,
  onClose,
  className,
  onPress,
}: IUnderstandModalProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`
          ${!visibility ? "hidden" : ""}
          overflow-y-auto overflow-x-hidden flex flex-col items-center fixed top-0 right-0 left-0 md:inset-0 h-modal md:h-full`}
      style={{ zIndex: 1000000, backgroundColor: "rgba(0,0,0,0.2)" }}
    >
      <div
        className={`
          relative p-4 w-auto w-xl h-screen font-gellix
          ${className}
        `}
        style={{ zIndex: 10000 }}
      >
        <div className='relative bg-black px-5 pt-5 top-[0%] xl:top-[30%] lg:top-[30%] md:top-[30%] rounded-xl shadow flex flex-col items-center'>
          <div className='lg:w-[900px] xl:w-[900px] md:w-[700px] w-screen text-center'>
            <div className='w-full flex items-end justify-end '>
              <BsX onClick={() => onClose?.()} size={25} strokeWidth={0.6} className='text-white' />
            </div>

            <div className='flex flex-col items-center justify-center text-tertiary font-semibold pb-5'>
              <div className=''>
                <BloomwellLogo isDark={false} />
              </div>
              <div className='text-base mt-2'>{t("Marktplatz für Cannabis-Apotheken")}</div>
            </div>

            <div className='pb-4'>
              <div className='uppercase font-extrabold text-4xl text-white font-gellix pb-4'>
                {t("you switch to executive pharmacy")}
              </div>

              <div className='text-primary-10 text-base text-center'>
                {t(
                  "You will be redirected to the desired pharmacy page. We transfer your user data and your selected product to \n the pharmacy so that you can complete your order quickly and easier",
                )}
              </div>
            </div>

            <div className='w-full flex items-center justify-center mb-10'>
              <div className='w-4/5 md:w-[30%]'>
                <FormButton
                  style={{ backgroundColor: "#2A9D8F" }}
                  title={
                    <div className='flex flex-row items-center justify-center gap-2'>
                      <div className=''>{t("In den Warenkorb")}</div>
                      <BsArrowRight size={20} strokeWidth={0.6} />
                    </div>
                  }
                  onPress={() => {
                    onPress?.();
                    onClose?.();
                  }}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IUnderstandModal;
