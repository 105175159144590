import FormButton from "@gruene-brise/common-ui/lib/form/FormButton";
import AuthorizationLayout from "@gruene-brise/common-ui/lib/layout/AuthorizationLayout";
import SignUpWrapper from "@gruene-brise/common-ui/webshop/SignUpWrapper";
import { useGraphql } from "@gruene-brise/data-access/hooks/useGraphql";
import useGraphqlError from "@gruene-brise/data-access/hooks/useGraphqlError";
import { SignUpSchema, SignupFormProps } from "@gruene-brise/data-access/schema/SignUpSchemes";
import { yupResolver } from "@hookform/resolvers/yup";
import FooterText from "@gruene-brise/common-ui/webshop/FooterText";
import { CreateAccountRequest, InsuranceType } from "libs/data-access/src/lib/api/generated";
import environment from "@gruene-brise/data-access/config/environment";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";

export function UserSignUp({
  onLogin,
  hideHeader,
  hideDoctor,
  className,
}: {
  onLogin?(): void;
  hideHeader?: boolean;
  hideDoctor?: boolean;
  className?: string;
}) {
  const backend = useGraphql();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const {
    register,
    setValue,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm<SignupFormProps>({
    defaultValues: {},
    resolver: yupResolver(SignUpSchema(t)),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const signUpMutation = useMutation((input: CreateAccountRequest) =>
    backend.createAccount({ input }),
  );
  const { showError } = useGraphqlError();
  const loginUrl = environment.algeaLoginUrl + "register/embed/";

  const onSubmit = async (e: SignupFormProps) => {
    setIsLoading(true);

    try {
      await signUpMutation.mutateAsync({
        email: e.email!,
        firstName: e?.firstName!,
        lastName: e.lastName!,
        newsletter: e.termsAndCondition,
        coverage: e?.coverage === "yes",
        insuranceType: e?.insurance as InsuranceType,
      });

      router.push({
        pathname: router.pathname,
        query: { done: "true" },
      });
    } catch (e) {
      showError(e);
    }
    setIsLoading(false);
  };

  const onPress = (e: any) => {
    e.preventDefault();
    if (!isValid) return;

    onSubmit(getValues());
  };

  return (
    <AuthorizationLayout
      title={t("Create Account")}
      onSubmit={onPress}
      className={`w-11/12 px-4 font-gellix ${className ?? ""}`}
      footer={
        hideDoctor ? (
          <></>
        ) : (
          <div className='w-full mt-[150px]'>
            <FooterText />
          </div>
        )
      }
      hideHeader={hideHeader}
      options={{
        successTitle: <div className={"mt-[40px]"}> {t("Account created!")}</div>,
        successDescription: (
          <div className={hideHeader ? "mb-[20px]" : ""}>
            <Trans>
              <div>Check your inbox, we have sent</div>
              <div>next steps to your email.</div>
            </Trans>
          </div>
        ),
      }}
      autoComplete={"off"}
    >
      <iframe
        className='w-full h-[650px] border-none'
        src={`${loginUrl}${i18n?.language}`}
      ></iframe>
    </AuthorizationLayout>
  );
}

export default UserSignUp;
