const DeliveryBusIcon = () => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.1 20.3508C10.1 21.3449 9.29412 22.1508 8.3 22.1508C7.30588 22.1508 6.5 21.3449 6.5 20.3508C6.5 19.3567 7.30588 18.5508 8.3 18.5508C9.29412 18.5508 10.1 19.3567 10.1 20.3508Z'
        fill='#233918'
      />
      <path
        d='M18.4999 20.3508C18.4999 21.3449 17.694 22.1508 16.6999 22.1508C15.7058 22.1508 14.8999 21.3449 14.8999 20.3508C14.8999 19.3567 15.7058 18.5508 16.6999 18.5508C17.694 18.5508 18.4999 19.3567 18.4999 20.3508Z'
        fill='#233918'
      />
      <path
        d='M4.0999 5.35156C3.43717 5.35156 2.8999 5.88883 2.8999 6.55156V18.5516C2.8999 19.2143 3.43717 19.7516 4.0999 19.7516H5.35992C5.63787 18.3822 6.84853 17.3516 8.2999 17.3516C9.75128 17.3516 10.9619 18.3822 11.2399 19.7516H12.4999C13.1627 19.7516 13.6999 19.2143 13.6999 18.5516V6.55156C13.6999 5.88883 13.1627 5.35156 12.4999 5.35156H4.0999Z'
        fill='#233918'
      />
      <path
        d='M17.3001 8.94922C16.6373 8.94922 16.1001 9.48648 16.1001 10.1492V17.4092C16.294 17.3699 16.4947 17.3492 16.7001 17.3492C18.1515 17.3492 19.3622 18.3799 19.6401 19.7492H20.9001C21.5629 19.7492 22.1001 19.212 22.1001 18.5492V12.5492C22.1001 12.231 21.9736 11.9257 21.7486 11.7007L19.3486 9.30069C19.1236 9.07565 18.8183 8.94922 18.5001 8.94922H17.3001Z'
        fill='#233918'
      />
    </svg>
  );
};

export default DeliveryBusIcon;
